import logo from './logo.svg';
import './App.css';
import SignupForm from './SignupForm';
import { analytics } from './firebase-config'; 
import { logEvent } from 'firebase/analytics';
import React, { useEffect, useRef } from 'react';
import Faq from './Faq'


function App() {
  const topRef = useRef(null);
  useEffect(() => {
    // Log a page_view event
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <div className="App" ref={topRef}>
      <header className="App-page">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-divider"/>
        <SignupForm topRef={topRef}/>
      </header>
      <div className="App-footer">
        <Faq/>
      </div>
    </div>
  );
}

export default App;
