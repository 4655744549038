import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getFunctions, httpsCallable } from 'firebase/functions';


const firebaseConfig = {
  apiKey: "AIzaSyDnPLn69fvKyMgAmyhR99SJa25aEwxLXMc",
  authDomain: "mangarecap-ai.firebaseapp.com",
  projectId: "mangarecap-ai",
  storageBucket: "mangarecap-ai.appspot.com",
  messagingSenderId: "323394748821",
  appId: "1:323394748821:web:0333f3b85ca15645da769c",
  measurementId: "G-4YX3N5Z2QY"
};

const app = initializeApp(firebaseConfig);

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcZM3kpAAAAAHfVciwRvle5Q3crxYF6eyZ-p3S5'),
  isTokenAutoRefreshEnabled: true
});

export const db = getFirestore(app);
export const analytics = getAnalytics(app);

const functions = getFunctions(app, 'https://mangarecap.ai/'); 
export const submitEmail = httpsCallable(functions, 'submitEmail');
