import React, { useState } from "react";
import './index.css';
import Go from '../Go';
import {DISCORD_INVITE} from '../constants';

const data = {
    title: "FAQ (How it works)",
    rows: [
        {
            title: "How does mangarecap.ai work?",
            content: <div className="faq-paragraphs">
                <div>Upload your manga to mangarecap.ai, and we'll slice it into pages and panels. GPT vision reads each page, crafting a dynamic script.</div>
                <div>Character profile pages are automatically extracted, crucial for accurate character identification.</div>
                <div>We highlight key panels and pages for visual impact in the recap video. An Elevenlabs voice narrates the script, culminating in a video that summarizes the manga volume.</div>
            </div>,
        },
        {
            title: "What formats of manga can I upload?",
            content: `Right now we only support PDF files.`,
        },
        {
            title: "How long does it take to convert an entire volume of manga into a video?",
            content: `It takes about 10-30 minutes, depending on the length of the manga. 
            Right now, I'm focussing on the quality of the output, but speed can be optimized in the future.`,
        },
        {
            title: "What are the processing costs involved?",
            content: <div className="faq-paragraphs"><div>Creating a recap video costs between $5.00 and $10.00, depending on the volume's length. The majority of the cost (90%)
            is for narration. I am currently using Elevenlabs which is the most expensive option available.</div><div>GPT Vision accounts for
            the rest of the cost, as it must read every page of the manga. My primary focus right now is on quality, though costs can be significantly optimized in the future.</div></div>,
        },
        {
            title: "Can I customize the voice used?",
            content: `Right now only one voice is used for narration (Patrick Bateman). But in the future, any Elevenlabs voice will be fair game.`,
        },
        {
            title: "Do you support manga in languages other than English?",
            content: `Any language will work!`,
        },
        {
            title: "How can I get in contact with the mangarecap.ai dev?",
            content: <div className="faq-paragraphs"><div>Please join the <Go to={DISCORD_INVITE}><span className={"faq-link"}>Discord</span></Go> and @ me!</div>
                <div>Alternatively, you can email me at nik@mangarecap.ai</div>
            </div>,
        },
    ],
};

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleRow = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    return (
        <div className="faq-container">
            {data.rows.map((row, index) => (
                <div key={index} className="faq-row">
                    <div className="faq-title" onClick={() => toggleRow(index)}>
                        {row.title}
                    </div>
                    {openIndex === index && (
                        <div className="faq-content">{row.content}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Faq;
