import React, { useState } from 'react';
import { submitEmail, analytics } from '../firebase-config';
import { logEvent } from 'firebase/analytics';
import './index.css';
import { DISCORD_INVITE } from '../constants';
import Go from '../Go';

const SignupForm = ({topRef}) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;

    setIsLoading(true); // Set loading to true
    logEvent(analytics, 'signup_submit', {
      content_type: 'text',
      item_id: 'signup_form'
    });

    try {
      const result = await submitEmail({ email });
      console.log('result', result.data);
      setEmail('');
      setEmailSubmitted(true);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('There was an error with your subscription.');
    } finally {
      setIsLoading(false); // Reset loading state
      if (topRef && topRef.current) {
        topRef.current.scrollTop = 0;
      }
    }
  };

  if (emailSubmitted) {
    return (
      <div className="mailingList">
        <div className="title">Thank you for subscribing!</div>
        <div className="info">
          <div className="action">Please verify your email and we'll be in touch as soon the closed beta is ready. In the meantime, feel free to check out our <Go to={DISCORD_INVITE}><span className="link">Discord</span></Go> where we'll be taking manga requests and sharing updates.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="mailingList">
      <div className="title">Bring any manga to <span className="backgroundClip">life</span> with the help of AI.</div>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="leaf@vinland.gl"
          required
          disabled={isLoading}
          className="emailInput"
        />
        <button type="submit" disabled={isLoading} className="button">Submit</button> 
      </form>
      <div className="info">
        <div className="action">Submit your email for an invite to the closed beta.</div>
      </div>
    </div>
  );
};

export default SignupForm;